<template>
  <AwlCard v-bind="{...$attrs, ...$props}" class="awl-user-card" :border="border" box-shadow>
      <template #title>
        <span class="d-block">{{ user.name }} {{ user.surname }}</span>
        <small class="user-role text-primary">{{ userRole }}</small>
        <hr>
    </template>
    <template #default>
      <p class="pb-3 m-0 small text-muted">
        {{ user.email }}
      </p>
      <slot name="footer">
        <div class="card-footer d-flex align-items-center justify-content-between">
          <router-link :to="{ name: 'users.edit', params: { id: user.id } }" class="btn btn-sm btn-outline-primary">Modifica</router-link>
          <router-link :to="{ name: 'users.show', params: { id: user.id } }" class="btn btn-sm btn-outline-info">Visualizza</router-link>
        </div>
      </slot>
    </template>
  </AwlCard>
</template>

<script>
export default {
  components: {
    AwlCard: () => import('./AwlCard'),
  },
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userRole () {
      return this.user.role === 'operator' ? this.user?.companyName : 'Amministratore';
    },
  },
};
</script>

<style lang="scss">
.awl-user-card {
  .awl-card--header {
    padding: 1rem !important;
    padding-bottom: 0 !important;
    .user-role {
      font-size: 12px;
    }
  }
  .awl-card--title {
      color: $dark !important;
      text-transform: initial !important;
      font-size: 1.5rem;
      line-height: 1.25rem;
      margin-bottom: 0;
    }
  .awl-card--content {
    padding: 0 1rem 1rem !important;
  }
  hr {
    background: $light;
    border: none;
    height: 1px;
  }
  .card-footer {
    border-top: 1px solid $light;
    padding-top: 1rem;
  }
}
</style>
